import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from "./plugins/i18n";
import VueMoment from 'vue-moment';
import VueApexCharts from 'vue-apexcharts'


Vue.config.productionTip = false

Vue.component('json-debug', () => import('@/components/Commons/ObjDebug'))
Vue.component('paginated-table', () => import('@/components/Commons/PaginatedTable'))
Vue.component('simple-table', () => import('@/components/Commons/SimpleTable') )
Vue.component('dialog-simple', () => import('@/components/Commons/DialogSimple') )


const moment = require('moment')

if(i18n.locale == 'it'){
  require('moment/locale/it')
}else if(i18n.locale == 'es'){
  require('moment/locale/es')
}else if(i18n.locale == 'pt'){
  require('moment/locale/pt')
}else if(i18n.locale == 'el'){
  require('moment/locale/el')
}else{
  require('moment/locale/en-gb')
}

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(VueMoment, {
  moment,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
