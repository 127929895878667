import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          receipts: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          receipt:null
     },
     getters:{
          receipts: state => state.receipts,
          receipt: state => state.receipt,
          meta: state => state.meta,
     },
     actions:{
          getReceipts({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET receiptS on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("admin/receipts/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setReceipts', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET receiptS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getReceipt({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET receipt on uid: '+data)

                    apiClient.get("admin/receipts/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setReceipt', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET receipt ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          exportReceipts({commit}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN EXPORT exportReceipts: ', params)

                    apiClient.post("admin/receipts/export", params).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN EXPORT exportReceipts ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateReceipt({commit}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN DELETE Receipt: ', params)

                    apiClient.delete("admin/receipts/single/"+params.receipt_uid+"/update/",params).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN DELETE Receipt ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteReceipt({commit}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN DELETE Receipt: ', params)

                    apiClient.delete("admin/receipts/single/"+params+"/delete/").then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN DELETE Receipt ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          uploader({commit}, formData){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPLOAD Receipt: ', formData)
                    apiClient.upload("admin/receipts/uploader/", formData).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPLOAD Receipt ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(state,err) {
               state.receipt = null
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setReceipts(state,resp){
               state.receipts = resp.data.data
               console.log('mutation setReceipts',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setReceipt(state,resp){
               state.receipt = resp.data.data
               console.log('mutation setReceipt',resp.data.data);
          }
     }
}