import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          users: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          user:null,
          errors: {},
     },
     getters:{
          errors: state => state.errors,
          users: state => state.users,
          user: state => state.user,
          meta: state => state.meta,
     },
     actions:{
          getUsers({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET USERS on page: '+state.meta.current_page, params)

                    if(!params){
                          params ={ roles : ['user']};
                    }

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("admin/users/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setUsers', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          exportUsers({commit}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN EXPORT USERS: ', params)

                  //  params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.post("admin/exports/generate/users", params).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN EXPORT USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET USER on uid: '+data)

                    apiClient.get("admin/users/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateProfile({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE Profile USER on uid: '+data.uid,data)

                    apiClient.patch("admin/users/single/"+data.uid+"/update-profile/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updatePrivacy({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE updatePrivacy USER on uid: '+data.uid,data)
                    apiClient.patch("admin/users/single/"+data.uid+"/update-privacy/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateRoles({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE ROLES USER on uid: '+data.uid,data)

                    apiClient.patch("admin/users/single/"+data.uid+"/update-roles/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updatePoints({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE POINTS USER on uid: '+data.uid,data)

                    apiClient.patch("admin/users/single/"+data.uid+"/update-points/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE POINTS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteSleeperUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN DELETE SLEEPER USER on uid: ', data)

                    apiClient.post("admin/users/delete-sleeper-user", data).then(resp => {
                         resolve(resp);
                         commit('error', null);
                     })
                     .catch(err => {
                         console.log('ADMIN DELETE SLEEPER USER ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          sendNewPassword({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE POINTS USER on uid: '+data.uid,data)

                    apiClient.patch("admin/users/single/"+data.uid+"/update-password/").then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN UPDATE POINTS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(state,err) {
               if(err.response){
                    state.errors = err.response.data.errors
               }else{
                    state.errors = {};
               }
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setUsers(state,resp){
               state.users = resp.data.data
               console.log('mutation setUsers',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setUser(state,resp){
               state.user = resp.data.data
               console.log('mutation setUser',resp.data.data);
          }
     }
}