<template>
     <div>
          <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              color="light"
              size="28"
            >
               <v-img :src="'/img/flags/'+language+'.png'"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
               <v-list-item-group
               v-model="language"
               color="primary"
               >
               <v-subheader> {{ $t('commons.change-locale') }} ({{language}})</v-subheader>
               <v-list-item v-for="language in languages" :key="language" :value="language" @click="changeLocale(language)">
                    
                    <v-list-item-avatar>
                          <v-img :src="'/img/flags/'+language+'.png'"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                         <v-list-item-title>{{language}}</v-list-item-title>
                    </v-list-item-content>
               </v-list-item>
               </v-list-item-group>
          </v-card-text>
        </v-card>
      </v-menu>
     </div>
</template>
<script>
export default {
     data: () => ({
          menu: false,
          language: null,
          languages: null,
     }),
     beforeMount(){
         this.language = this.$i18n.locale
          this.languages =  this.$i18n.availableLocales
     },
     methods:{
          changeLocale(item){
               console.log(item)
               this.language = item
                this.$i18n.locale = this.language
                localStorage.setItem('language', this.language) 
          }
     }
}
</script>