<template>
     <div>
          <v-menu
               v-model="dPicker"
               :close-on-content-click="false"
               :nudge-right="40"
               transition="scale-transition"
               offset-y
               min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedDate"
                :label="label"
                :placeholder="placeholder"
                :hint="hint"
                readonly
                :error-messages="errors"
                v-bind="attrs"
                v-on="on"
                :messages="inputMessage"
                :outlined="outlined"
                :dense="dense"
            ></v-text-field>
        </template>
          <v-date-picker
               @input="dPicker = false"
               v-model="localModel"
               :locale="$root.$i18n.locale+'-'+$root.$i18n.locale"
               first-day-of-week="1"
               :min="min"
               :max="max"
          ></v-date-picker>
          </v-menu>
     </div>
</template>
<script>
export default {
     name: "datepicker",
     props:{
          date:{
               type: String,
               required: false
          },
          max: {
               type: String,
          },
          min: {
               type: String,
          },
          label: {
               type: String,
               required: true
          },
          placeholder: {
               type: String,
               required: false,
               default: "SELECT A DATE"
          },
          inputMessage:{
               type: String,
               default: "",
          },
          errors:{
               type: Array,
               required: false,
          },
          hint:{
               type: String,
               default: 'select a date'
          },
          outlined:{
               type: Boolean,
               default: false
          },
          dense:{
               type: Boolean,
               default: false
          }
     },
     computed:{
          localModel: {
                get() {
                    return (this.date) ? this.$moment(this.date).format('YYYY-MM-DD') : null
                },
                set(value) {
                    this.$emit('update:date', value)
                }
          },
          formattedDate: {
              get() {
                return (this.date) ? this.$moment(this.date).format('dddd, D MMMM YYYY') : null
              },
              set() {

              }
          }
     },
     data () {
          return {
            dPicker: false
          }
     },
}
</script>