import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          stores: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          store:null
     },
     getters:{
          stores: state => state.stores,
          store: state => state.store,
          meta: state => state.meta,
     },
     actions:{
          getStores({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET STORES on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("admin/stores/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setStores', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getStore({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET STORES on uid: '+data)

                    apiClient.get("admin/stores/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setStore', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateStore({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET STORES on uid: '+data.store_uid, data)

                    apiClient.patch("admin/stores/single/"+data.store_uid+'/update', data).then(resp => {
                         resolve(resp);
                         commit('setStore', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createStore({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN CREATE STORES on ',data)

                    apiClient.post("admin/stores/create/",data).then(resp => {
                         resolve(resp);
                         commit('setStore', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          attachManagerStore({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN attachManager TO STORES on ',data)

                    apiClient.patch("admin/stores/single/"+data.store_uid+"/manager/attach",data).then(resp => {
                         resolve(resp);
                         commit('setStore', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          detachManagerStore({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN attachManager TO STORES on ',data)

                    apiClient.patch("admin/stores/single/"+data.store_uid+"/manager/detach",data).then(resp => {
                         resolve(resp);
                         commit('setStore', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET STORES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setStores(state,resp){
               state.stores = resp.data.data
               console.log('mutation setStores',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setStore(state,resp){
               state.store = resp.data.data
               console.log('mutation setStore',resp.data.data);
          }
     }
}