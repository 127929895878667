<template>
     <div>
          <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              color="light"
              size="38"
            >
               <v-icon light>mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center" v-if="!isAuthenticated">
              <v-btn
                depressed
                rounded
                text
                :to="{name: 'login'}"
              >
                <v-icon class="mr-3">mdi-login</v-icon> {{ $t('commons.login') }}
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                :to="{name: 'register'}"
              >
                <v-icon class="mr-3">mdi-pencil</v-icon>{{ $t('commons.register') }}
              </v-btn>
            </div>
            <div class="mx-auto py-3 text-center" v-if="isAuthenticated && user">
              <v-avatar
                size="50"
                color="grey darken-1"
                icon
              >
                <v-icon>mdi-account</v-icon>
              </v-avatar>
            </div>
            <v-divider></v-divider>
            <div class="mx-auto py-3 text-center" v-if="isAuthenticated && user">
              {{user.email}}
              <br><br>
              {{user.profile.fullname}}
               <br><br>
              {{user.country}}-{{user.language}}
            </div>
            <v-divider></v-divider>
            <div class="mx-auto mt-2 text-center" v-if="isAuthenticated">
              <v-btn
                depressed
                rounded
                text
                @click="logout()"
              >
                <v-icon class="mr-3">mdi-logout</v-icon> {{ $t('commons.logout') }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
     </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     data: () => ({
          menu: false
     }),
     computed: {
     ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          user: 'user/user',
      })
     },
     methods:{
       logout(){
         this.$store.dispatch('auth/logout')
         .then((response) => {
                    console.log('logout success!',response)
                    this.$router.push({name:'login'})
          })
       }
     }
}
</script>