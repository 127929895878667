import apiClient from '@/plugins/apiClient'
//import i18n from "@/plugins/i18n";
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          receipt:null
     },
     getters:{
          receipt: state => state.receipt,
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          success(err) {
               console.log('mutation error',err);
          },
          setReceipt(state,resp){
               state.receipt = resp.data.data
               console.log('mutation setReceipt',resp.data.data);
          },
     },
     actions:{
          createReceipt({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('manager createReceipt:', data)

                    apiClient.post("manager/receipts/create/", data)
                    .then(resp => {
                         resolve(resp);
                         commit('setReceipt', resp);
                         commit('success', resp);
                     })
                    .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                    });
               })
          },
     },
}