import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          users: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          user:null
     },
     getters:{
          users: state => state.users,
          user: state => state.user,
          meta: state => state.meta,
     },
     actions:{
          searchUsers({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('manager GET USERS on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("manager/users/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setUsers', resp);
                     })
                     .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('manager GET USER on uid: '+data)

                    apiClient.get("manager/users/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('REGISTER NEW USER',data)
                    apiClient.post("manager/users/create", data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('store auth actions REGISTRATION error ', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          restoreUser({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('RESTORE NEW USER', data)
                    apiClient.post("manager/users/restore/", data).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('store auth actions REGISTRATION error ', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateProfile({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('manager UPDATE Profile USER on uid: '+data.uid,data)

                    apiClient.patch("manager/users/single/"+data.uid+"/update-profile/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('manager UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updatePrivacy({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('manager UPDATE updatePrivacy USER on uid: '+data.uid,data)
                    apiClient.patch("manager/users/single/"+data.uid+"/update-privacy/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('manager UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateSettings({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('manager UPDATE updatePrivacy USER on uid: '+data.uid,data)
                    apiClient.patch("manager/users/single/"+data.uid+"/update-settings/", data).then(resp => {
                         resolve(resp);
                         commit('setUser', resp);
                     })
                     .catch(err => {
                         console.log('manager UPDATE ROLES ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          },
          updateUserPoints({commit}, points){
               console.log('updateUserPoints',points);
               commit('setPoints', points);
          }
     },
     mutations:{
          error(state,err) {
               //state.user = null,
               state.error = err.data,
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setUsers(state,resp){
               state.users = resp.data.data
               console.log('mutation setUsers',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setUser(state,resp){
               state.user = resp.data.data
               console.log('mutation setUser',resp.data.data);
          },
          setPoints(state,points){
               console.log('add points',points)
               state.user.points.total_points = (state.user.points.total_points + Math.floor(points))
               console.log('mutation setPoints',state.user.points.total_point);
          }
     }
}