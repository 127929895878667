import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          completedcoupons: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          completedcoupon:null
     },
     getters:{
          completedcoupons: state => state.completedcoupons,
          completedcoupon: state => state.completedcoupon,
          meta: state => state.meta,
     },
     mutations:{
          error({dispatch},err) {
               dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.error'), type: 'error'}, {root: true})
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setCoupons(state,resp){
               state.completedcoupons = resp.data.data
               console.log('mutation setCoupons',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setCoupon(state,resp){
               state.completedcoupon = resp.data.data
               console.log('mutation setCoupon',resp.data.data);
          },
     },
     actions:{
          getUserCouponsCompleted({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('admin GET USER couponS on uid: '+uid)

                    apiClient.get("admin/users/single/"+uid+"/completed-coupons?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setCoupons', resp);
                     })
                     .catch(err => {
                         console.log('admin GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     },
}