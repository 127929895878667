export default {
     namespaced: true,
     state: {
         message: '',
         type: '',
         visible: false,
         text: null,
         timeout: 4000,
         multiline: false,
     },
 
     getters: {},
 
     actions: {
         showSnackBar({commit}, payload) {
             console.log('store actions showSnackBar payload ', payload)
             commit('show', payload);
         },
 
         resetSnackBar({commit}) {
             console.log('store actions resetSnackBar')
             commit('clear');
         },
     },
 
     mutations: {
 
         show(state, payload) {
             console.log('store mutations show ', payload)
             state.timeout = 4000
 
             if (payload.err != undefined && payload.err.data && Object.prototype.hasOwnProperty.call(payload.err.data, 'errors'))
             {
                 state.timeout = 8000
                 console.log('payload.data.errors ' , payload.err.data.errors)
                 for (const [key, value] of Object.entries(payload.err.data.errors)) {
                     state.message += key+' '+value + '<br>'
                 }
             } else {
                 state.message = payload.message;
             }
 
             state.multiline = (payload.message.length > 50)
             state.type = payload.type;
 
             if (payload.multiline) {
                 state.multiline = payload.multiline
             }
 
             if (payload.timeout) {
                 state.timeout = payload.timeout
             }
 
             state.visible = true
         },
 
         timeout(state, payload) {
             state.timeout = payload
         },
 
         clear(state) {
             console.log('store mutations clear')
             state.message = '';
             state.type = '';
             state.visible = false
             state.multiline = false
             state.timeout = 4000
         }
     }
 }
 