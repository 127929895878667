import commons from './it/commons.json'
import attributes from './it/attributes.json'
import errors from './it/errors.json'
import roles from './it/roles.json'
import user from './it/user.json'
import admin from './it/admin.json'
import manager from './it/manager.json'
import router from './it/router.json'
import filters from './it/filters.json'
import privacy from './it/privacy.json'
import analytics from './it/analytics.json'

const lang = {
     manager: manager,
     admin: admin,
     user: user,
     roles: roles,
     errors: errors,
     attributes: attributes,
     commons: commons,
     analytics: analytics,
     filters: filters,
     privacy: privacy,
     router: router
}
export default lang