import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          analytics:[],
          single: null
     },
     getters:{
          analytics: state => state.analytics,
          single: state => state.single,
     },
     actions:{
          getAnalytics({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET Analytics on page: ', data)

                    apiClient.get("admin/analytics/"+data.today).then(resp => {
                         resolve(resp);
                         commit('setAnalytics', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET analyticsS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getSingleAnalytics({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET analytics on: ', data)
                    
                    var params = (data) ? qs.stringify(data, { arrayFormat: 'indices' }) : null;

                    console.log(params)

                    apiClient.get("admin/analytics/single/"+data.table+"/?"+ params ).then(resp => {
                         resolve(resp);
                         commit('setSingleAnalytics', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET analytics ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          rigenerateAnalytics({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET analytics on uid: '+data)
                    apiClient.post("admin/analytics/rigenerate",data).then(resp => {
                         resolve(resp);
                         commit('setAnalytics', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET analytics ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          }
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          setAnalytics(state,resp){
               state.analytics = resp.data
               console.log('mutation setAnalytics',resp);
          },
          setSingleAnalytics(state,resp){
               state.single = resp.data.data
               console.log('mutation setSingleAnalytics',state.single);
          }
     }
}