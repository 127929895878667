import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          stores: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          store:null
     },
     getters:{
          stores: state => state.stores,
          store: state => state.store,
          meta: state => state.meta,
     },
     mutations:{
          error({dispatch},err) {
               dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.error'), type: 'error'}, {root: true})
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setStores(state,resp){
               state.stores = resp.data.data
               console.log('mutation setStores',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setStore(state,resp){
               state.store = resp.data.data
               console.log('mutation setStore',resp.data.data);
          },
     },
     actions:{
          getUserStores({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('admin GET USER storeS on uid: '+uid)

                    apiClient.get("admin/users/single/"+uid+"/stores?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setStores', resp);
                     })
                     .catch(err => {
                         console.log('admin GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     },
}