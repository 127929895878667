import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
        authenticated: (localStorage.getItem('authenticated')=="true"),
        token: localStorage.getItem('access_token'),
        user: localStorage.getItem('user'),
        roles: localStorage.getItem('roles'),
        country: localStorage.getItem('country'),
        timezone: localStorage.getItem('timezone'),
        language: localStorage.getItem('language'),
     },
     getters:{
         roles: state => (state.roles) ? state.roles.split(','):[],
         user: state => (state.user) ? state.user.data : null,
         isAuthenticated: state => state.authenticated,
         language: state => state.language,
         country: state => state.country,
         timezone: state => state.timezone,
         isAdmin: state => (state.roles.includes('admin')),
         isManager: state => (state.roles.includes('manager') || state.roles.includes('admin')),
         isUser: state => (state.roles.includes('user')),
     },
     actions:{
         login({commit},data){
          return new Promise((resolve, reject) => {
               console.log('LOGIN USER',data)
               apiClient.post("login", data).then(resp => {
                    commit('success', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    console.log('store auth actions AUTH_LOGIN error ', err)
                    commit('error', err);
                    reject(err);
                });
          })
         },
         logout({commit},data){
            return new Promise((resolve, reject) => {
                console.log('logout',data)
                apiClient.post("logout", data).then(resp => {
                     commit('logout', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions AUTH_LOGIN error ', err)
                     commit('error', err);
                     reject(err);
                     commit('logout');
                 });
           })
         },
         forceLogout({commit}) {
            commit('logout')
         },
     },
     mutations:{
        error(err) {
            console.log('mutation error',err);
        },
        success(state,resp) {
            console.log('mutation success',resp);
            localStorage.setItem('token', resp.access_token)
            localStorage.setItem('roles', resp.roles)
            localStorage.setItem('country', resp.country)
            localStorage.setItem('timezone', resp.timezone)
            localStorage.setItem('language', resp.language)
            localStorage.setItem('authenticated', true)
            state.authenticated = true
            state.roles = resp.roles.join()
        },
        
        logout(state) {
            localStorage.clear();
            state.status = '';
            state.roles = '';
            state.authenticated = false;
            state.token = '';
            state.user = false;
        },
     }
}