import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          receipts: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          receipt:null
     },
     getters:{
          receipts: state => state.receipts,
          receipt: state => state.receipt,
          meta: state => state.meta,
     },
     mutations:{
          error({dispatch},err) {
               dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.error'), type: 'error'}, {root: true})
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setReceipts(state,resp){
               state.receipts = resp.data.data
               console.log('mutation setReceipts',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setReceipt(state,resp){
               state.receipt = resp.data.data
               console.log('mutation setReceipt',resp.data.data);
          },
     },
     actions:{
          getUserReceipts({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('admin GET USER receiptS on uid: '+uid)

                    apiClient.get("admin/users/single/"+uid+"/receipts?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setReceipts', resp);
                     })
                     .catch(err => {
                         console.log('admin GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     },
}