<template>
<v-container fluid>
    <v-form ref="form" v-model="valid" >
                    <v-card-text>
                         <v-row>
                              <v-col cols="12">
                                   <v-text-field 
                                        type="email"
                                        @keyup.enter="submit"
                                        v-model="log.email"
                                        :label="$t('attributes.email.label')"
                                        name="email"
                                        required
                                        prepend-icon="mdi-email"
                                        :hint="$t('attributes.email.hint')"
                                        :error-messages="errors.email"
                                        @focus="errors.email = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                   <v-text-field
                                   ref="password"
                                   required
                                   @keyup.enter="submit"
                                   v-model="log.password"
                                   :prepend-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                   :type="viewPassword ? 'text' : 'password'"
                                   name="current-password"
                                   :label="$t('attributes.password.label')"
                                   :hint="$t('attributes.password.hint')"
                                   @click:prepend="viewPassword = !viewPassword"
                                   :error-messages="errors.password"
                                   @focus="errors.password = []"
                                   >
                                   </v-text-field>
                              </v-col>
                              <v-col cols="12">
                                   <v-btn
                                   block
                                   color="primary"
                                   @click="submit"
                                   :loading="loading"
                                   >{{ $t('commons.login') }}</v-btn>
                              </v-col>
                         </v-row>
                    </v-card-text>
              </v-form>
     </v-container>
</template>
<script>
export default {
data: () => ({
    log:{
         email: null,
         password: null,
    },
    errors:{},
    valid: false,
    loading: false,
    viewPassword: false,
}),
methods:{
     async submit(event){
          this.loading = true
          this.$refs.form.resetValidation()
          event.preventDefault()
          
          this.$store.dispatch('auth/login',this.log)
               .then((response) => {
                    this.message = response.data.message
                    console.log('LOGIN success!',response)
                    this.$store.dispatch('user/getUser')
                    var roles = response.data.roles
                    if(roles.length == 1){
                         this.$router.push({name: roles[0]+".dashboard"})
                    }else{
                      this.$router.push({name:"dashboard"})
                    }
                    this.loading = false;
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    if(err.response.status == 401){
                         this.$router.push({name:"resend-email-verification",query:{'email':this.log.email}})
                    }
                    this.loading = false;
               })   
     }
},
}
</script>