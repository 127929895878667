import commons from './en/commons.json'
import attributes from './en/attributes.json'
import errors from './en/errors.json'
import roles from './en/roles.json'
import user from './en/user.json'
import admin from './en/admin.json'
import manager from './en/manager.json'
import router from './en/router.json'
import filters from './en/filters.json'
import analytics from './en/analytics.json'
import privacy from './en/privacy.json'

const lang = {
     manager: manager,
     admin: admin,
     user: user,
     roles: roles,
     errors: errors,
     attributes: attributes,
     commons: commons,
     analytics: analytics,
     filters: filters,
     privacy: privacy,
     router: router
}
export default lang