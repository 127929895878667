<template>
  <div>
    <app-header></app-header>
    <v-container class="py-5 text-center">
      <v-row>
        <v-col>
          <h1>ERROR</h1>
          <p v-if="this.$route.query.error">
            {{this.$route.query.error}}
          </p>
          <p v-else>
            COMMONS ERROR NOT IDENTIFIED
          </p>
          <p>
            <v-btn color="error" href="/">RETRY</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'

export default {
  name: 'ErrorPage',
  components: {
    'app-footer': Footer,
    'app-header': Header,
  },
  data: () => ({
      env: process.env,
      title: process.env.VUE_APP_BASE_NAME
  }),
}
</script>
