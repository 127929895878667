<template>
<div>
      <v-sheet 
            color="white lighten-4"
          ><v-img class="ma-4" :src="require('@/assets/logo.svg')"></v-img>  
      </v-sheet>
      <v-divider></v-divider>
     <v-list
        nav
        dense
        class="mb-5 pb-5"
      >
        <v-list-item-group
          active-class="primary--text"
          v-if="!isAuthenticated"
        >
          <v-list-item :to="{name: 'home'}" exact>
            <v-list-item-title>
                 <v-icon light class="mr-3">mdi-home</v-icon> {{ $t('commons.home') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'login'}" exact>
            <v-list-item-title >
                 <v-icon light class="mr-3">mdi-login</v-icon> {{ $t('commons.login') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'register'}" exact>
            <v-list-item-title>
                  <v-icon light class="mr-3">mdi-pencil</v-icon>{{ $t('commons.register') }}
            </v-list-item-title>
          </v-list-item>

        </v-list-item-group>
        <v-list-item-group
          active-class="primary--text"
          v-if="isAuthenticated"
        >
          <v-list-item :to="{name: 'dashboard'}" exact>
            <v-list-item-title>
                 <v-icon light class="mr-3">mdi-home</v-icon> {{ $t('commons.home') }}
            </v-list-item-title>
          </v-list-item>
          <template v-for="role in roles" >
            <v-list-group
              v-if="role!='user'"
              :value="true"
              :prepend-icon="$t('roles.'+role+'.icon')"
              :key="role"
            >
              <template v-slot:activator>
                <v-list-item-title>{{$t('roles.'+role+'.title')}}</v-list-item-title>
              </template>

              <v-list-item :to="{name: role +'.dashboard'}" exact>
                <v-list-item-title>
                    <v-icon light class="mx-3">mdi-home</v-icon> {{ $t('commons.dashboard') }} {{$t('roles.'+role+'.title')}}
                </v-list-item-title>
              </v-list-item>

              <template v-for="item in models">
              <v-list-item :key="item.to" :to="{'name': item.to}" exact v-if="item.roles.includes(role)">
                <v-list-item-title>
                    <v-icon light class="mx-3">{{item.icon}}</v-icon> {{ $t('router.'+item.to) }}
                </v-list-item-title>
              </v-list-item>
              </template>

            </v-list-group>
          </template>
        </v-list-item-group>
        
     </v-list>
     <v-footer absolute class="mt-2 text-center">
      <div class="text-center mb-0"><small>version: {{env.VUE_APP_VERSION}} - {{env.NODE_ENV}}</small></div>
     </v-footer>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          roles: 'auth/roles'
      })
     },
     data:()=>({
       env: process.env,
       models:[
         {
           to:"admin.users.index",
           icon: "mdi-account-group",
           roles: ['admin'],
         },
         {
           to:"admin.stores.index",
           title: "stores",
           icon: "mdi-store",
           roles: ['admin'],
         },
         {
           to:"admin.coupons.index",
           title: "coupons",
           icon: "mdi-ticket",
           roles: ['admin'],
         },
         {
           to:"admin.receipts.index",
           title: "receipts",
           icon: "mdi-cash-register",
           roles: ['admin'],
         },
        //  {
        //    to:'admin.images',
        //    title: "images",
        //    icon: "mdi-image-multiple",
        //    roles: ['admin']
        //  },
        //  {
        //    to:'admin.posts',
        //    title: "posts",
        //    icon: "mdi-post",
        //    roles: ['admin']
        //  },
         {
           to: 'admin.analytics.index',
           title: "analytics",
           icon: "mdi-chart-areaspline",
           roles: ['admin']
         },
         {
           to: 'admin.exports.index',
           title: "exports",
           icon: "mdi-file-download",
           roles: ['admin']
         },
        //  {
        //    to:'admin.categories',
        //    title: "categories",
        //    icon: "mdi-format-list-bulleted-type",
        //    roles: ['admin']
        //  },
         {
           to:'manager.stores.index',
           title: "stores",
           icon: "mdi-store",
           roles: ['manager']
         },
         {
           to:'manager.users.index',
           title: "users",
           icon: "mdi-account-group",
           roles: ['manager']
         },
         {
           to:'manager.receipts.index',
           title: "receipts",
           icon: "mdi-cash-register",
           roles: ['manager']
         },
         {
           to:'manager.analytics.index',
           title: "analytics",
           icon: "mdi-chart-areaspline",
           roles: ['manager']
         }
       ]
     }),
}
</script>

