import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          exportslist: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          export:null
     },
     getters:{
          exportslist: state => state.exportslist,
          export: state => state.export,
          meta: state => state.meta,
     },
     actions:{
          getExports({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET exportS on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("admin/exports/?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setExports', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET exportS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getExport({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET exportS on uid: '+data)

                    apiClient.get("admin/exports/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setExport', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET exportS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateExport({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET exportS on uid: '+data.id)
                    apiClient.patch("admin/exports/update/"+data.id,data).then(resp => {
                         resolve(resp);
                         commit('setExport', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET exportS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteExport({commit}, id){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET exportS on uid: '+id)
                    apiClient.delete("admin/exports/delete/"+id).then(resp => {
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET exportS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setExports(state,resp){
               state.exportslist = resp.data.data
               console.log('mutation setexports',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setExport(state,resp){
               state.export = resp.data.data
               console.log('mutation setexport',resp.data.data);
          }
     }
}