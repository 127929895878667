import apiClient from '@/plugins/apiClient'
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          coupons: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          coupon:null,
          errors:{}
     },
     getters:{
          coupons: state => state.coupons,
          coupon: state => state.coupon,
          meta: state => state.meta,
     },
     mutations:{
          error(state, err) {
               state.errors = err.response
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setCoupons(state,resp){
               state.coupons = resp.data.data
               console.log('mutation setCoupons',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setCoupon(state,resp){
               state.coupon = resp.data.data
               console.log('mutation setCoupon',resp.data.data);
          },
     },
     actions:{
          getUserCoupons({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('admin GET USER couponS on uid: '+uid)
                    apiClient.get("admin/users/single/"+uid+"/coupons?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setCoupons', resp);
                     })
                     .catch(err => {
                         console.log('admin GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          checkUserCoupons({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('admin GET USER couponS on uid: '+uid)
                    apiClient.get("manager/users/single/"+uid+"/coupons?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setCoupons', resp);
                     })
                     .catch(err => {
                         console.log('admin GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     },
}