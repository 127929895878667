<template>
  <v-card>
    <v-footer
      :padless="true"
    >
      <v-card
        flat
        tile
        width="100%"
        class="primary text-center"
      >
        <v-card-text  class="white--text">
          <v-btn
               v-for="link in links"
               :key="link.url"
               :to="{name:link.url}"
               color="white"
               text
               plain
               class="my-2"
               >
               {{ link.text }}
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          <small>Copyright {{ new Date().getFullYear() }} - Triumph Global Sales AG. All rights reserved.</small>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      links:[
          {
               text:"Home",
               url:"home",
          },
          {
               text:"Register",
               url:"register",
          },
          {
               text:"Login",
               url:"login",
          },
          {
               text:"Resend Card",
               url:"resend-card",
          },
          {
               text:"Request Delete",
               url:"request-delete",
          },
          {
               text:"Resend Sms",
               url:"resend-sms-verification",
          },
          {
               text:"Resend Email",
               url:"resend-email-verification",
          },
          {
               text:"Support",
               url:"support",
          }
      ]
    }),
  }
</script>