<template>
<v-container >
         <v-form ref="form" v-model="valid" >
                    <v-card-text v-if="store">
                         <v-row>
                              <v-col>
                                   <CreateUserForm :store="store"></CreateUserForm>
                              </v-col>
                         </v-row>
                    </v-card-text>
                     <v-card-text v-if="!store">
                         <v-list-item
                                  v-for="store in stores"
                                  :key="store.store_uid"
                                  @click="storeSelelected(store.store_code)"
                                  :to="{'name':'register-store', 'params':{'store_code':store.store_code}}"
                             >
                                   <!-- <v-list-item-icon>
                                        
                                   </v-list-item-icon> -->
                                   <v-list-item-icon>
                                        <v-icon class="me-2">mdi-store</v-icon>
                                        <v-avatar size="22">
                                             <v-img :src="'/img/flags/'+store.country.toLowerCase()+'.png'"></v-img>
                                        </v-avatar>
                                   </v-list-item-icon>
                                   <v-list-item-content>
                                        
                                        <h3>{{store.name}}</h3>
                                        <small>{{store.city}}</small>
                                   </v-list-item-content>
                             </v-list-item>
                         
                     </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions v-if="!store">
                         <v-pagination
                              v-model="meta.current_page"
                              :length="meta.last_page"
                              :total-visible="5"
                              :disabled="loading" 
                              @input="getStores(meta)"
                         ></v-pagination>
                    </v-card-actions>
             
          </v-form>
     </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import CreateUserForm from "@/components/Manager/Users/CreateUserForm";
export default {
     name:"FormRegistration",
     components:{
          CreateUserForm
     },
      computed: {
     ...mapGetters({
          meta: 'general/stores_meta',
          stores: 'general/stores',
      }),
     },
     data: () => ({
          reg:{
               store_uid: null,
               email: null,
               password: null,
               password_confirmation: null,
          },
          errors:{},
          store: null,
          valid: false,
          loading: false,
          viewPassword: false,
     }),
     beforeMount(){
          if(!this.stores){
                    console.log('NO STORE CHOOSE')
                    this.getStores()
          }
     },
     methods:{
          async submit(event){
               this.loading = true
               this.$refs.form.resetValidation()
               event.preventDefault()
               
               this.$store.dispatch('register/register',this.reg)
                    .then((response) => {
                         this.message = response.data.message
                         console.log('register success!',response)
                         this.loading = false;
                         this.$router.push({name:"login"})
                    })
                    .catch((err) => {
                         console.error('register ERROR!',err)
                         this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    })   

          },
          async getStores(){
               this.loading = true
               this.$store.dispatch('general/setMeta',this.meta) 
               this.$store.dispatch('general/getStores',this.reg) 
               .then((response) => {
                    this.loading = false;
                    console.log(response.data.data)

                    if(this.$route.params.store_code){
                         console.log(this.$route.params)
                         this.storeSelelected(this.$route.params.store_code)
                    }
               })
          },
          
          storeSelelected(store_code){
               this.store = this.stores.find(function(store) {
                    if(store.store_code == store_code)
                         return store;
               });
               this.reg.store_uid = this.store.store_uid;
               this.reg.country = this.store.country;
          },
     },
}
</script>