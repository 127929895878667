<template>
  <v-app>
    <v-main>
        <div v-if="env.NODE_ENV!='production'">
          <v-progress-linear striped value="100" height="30px"  color="light-blue">
            <h4 class="text-uppercase" dark>{{env.NODE_ENV}}</h4>
          </v-progress-linear>
        </div>
      <router-view></router-view>
      <snack-bar></snack-bar>
    </v-main>
  </v-app>
</template>

<script>
import SnackBar from "@/components/Commons/SnackBar";

export default {
  name: 'App',
  components:{
    'snack-bar':SnackBar
  },
  data: () => ({
      env: process.env,
  }),
};
</script>


<style>
.vh-100{
  min-height: 94vh;
}
</style>