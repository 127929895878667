<template>

  <v-container fluid>
    <v-row center class="text-center vh-50">
      <v-col cols="12"></v-col>
      <v-col cols="12">
        <v-img
          :src="require('@/assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
        <h2> {{ $t('commons.welcome') }} </h2>
        <h1 class="display-2 font-weight-bold mb-3 primary--text text-uppercase" >
         {{title}}
        </h1>
        <v-btn :to="{name: 'login'}" color="primary"  class="ma-3">{{ $t('commons.login') }}</v-btn>
        <v-btn :to="{name: 'register'}" color="primary"  class="ma-3">{{ $t('commons.register') }}</v-btn>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </v-container>

</template>

<script>

  export default {
    name: 'HelloWorld',
    components:{ },
    props:{
      title:{
        type: String
      }
    },
    data: () => ({
      //
    }),
  }
</script>

<style scoped>
.vh-50{
  height: 50vh;
}
</style>