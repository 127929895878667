<template>
     <v-row>
          <v-col cols="12" class="my-3">
               <h2>{{store.name}}</h2>
          </v-col>
          
          <v-col cols="12" v-if="!message">
               <v-row>
                    <v-col cols="12" md="2">
                         <v-select
                              v-if="titles"
                              :items="titles"
                              :label="$t('attributes.title_name.label')"
                              item-text="title"
                              item-value="title"
                              return-object
                              :error-messages="errors.title_name"
                              @focus="errors.title_name = null"
                              @change="changeTitle"
                              dense
                         ></v-select>
                    </v-col>
                    <v-col cols="6" md="5">
                         <v-text-field
                                   v-model="form.firstname"
                                   :label="$t('attributes.firstname.label')"
                                   :placeholder="$t('attributes.firstname.placeholder')"
                                   :hint="$t('attributes.firstname.hint')"
                                   :error-messages="errors.firstname"
                                   @focus="errors.firstname = null"
                                   dense
                                   
                         ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="5">
                         <v-text-field
                                   v-model="form.lastname"
                                   :label="$t('attributes.lastname.label')"
                                   :placeholder="$t('attributes.lastname.placeholder')"
                                   :hint="$t('attributes.lastname.hint')"
                                   :error-messages="errors.lastname"
                                   @focus="errors.lastname = null"
                                   dense
                                   
                         ></v-text-field>
                    </v-col>
                    <v-col cols="12" >
                         <datepicker 
                                   :label="$t('attributes.date_of_birth.label')"
                                   :placeholder="$t('attributes.date_of_birth.placeholder')"
                                   :date.sync="form.date_of_birth"
                                   :hint="'min 18'"
                                   :min="mindate"
                                   :max="maxdate"
                                   :errors="errors.date_of_birth"
                         ></datepicker>
                    </v-col>
                    <v-col cols="12">
                         <v-text-field
                                   v-model="form.email"
                                   :label="$t('attributes.email.label')"
                                   :placeholder="$t('attributes.email.placeholder')"
                                   :hint="$t('attributes.email.hint')"
                                   :error-messages="errors.email"
                                   @focus="errors.email = null"
                                   dense
                         ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                         <v-select
                              v-if="prefixes"
                              v-model="form.prefix"
                              :items="prefixes"
                              :label="$t('attributes.prefixes.label')"
                              :item-text="textPrefixItem"
                              item-value="prefix"
                              return-object
                              :error-messages="errors.phone"
                              @change="changePrefix"
                              dense
                         ></v-select>
                    </v-col>
                    <v-col cols="8">
                         <v-text-field
                                   type="number"
                                   v-model="form.phone_number"
                                   :label="$t('attributes.phone.label')"
                                   :placeholder="$t('attributes.phone.placeholder')"
                                   :hint="$t('attributes.phone.hint')"
                                   :error-messages="errors.phone"
                                   @focus="errors.phone = null"
                                   dense
                         ></v-text-field>
                    </v-col>
                    <v-col cols="7">
                         <v-text-field
                                   type="text"
                                   v-model="form.city"
                                   :label="$t('attributes.city.label')"
                                   :placeholder="$t('attributes.city.placeholder')"
                                   :hint="$t('attributes.city.hint')"
                                   :error-messages="errors.city"
                                   @focus="errors.city = null"
                                   dense
                         ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                         <v-text-field
                                   type="text"
                                   v-model="form.zip_code"
                                   :label="$t('attributes.zip_code.label')"
                                   :placeholder="$t('attributes.zip_code.placeholder')"
                                   :hint="$t('attributes.zip_code.hint')"
                                   :error-messages="errors.zip_code"
                                   @focus="errors.zip_code = null"
                                   dense
                         ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                         <label>
                         <div style="white-space: pre-line">
                              <b>{{ $t('privacy.privacy.label')}}</b>
                              <br> {{ $t('privacy.privacy.text')}}
                         </div>
                         </label>
                         <v-checkbox
                                   v-model="form.general"
                                   value
                                   :error-messages="errors.general"
                                   @click="errors.general = null"
                         >
                         <template v-slot:label>
                              <div>
                                   <b>{{ $t('privacy.general.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.general.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.general.link')">{{ $t('privacy.general.text_link')}}</a>
                                   </small>
                              </div>
                         </template>
                         </v-checkbox>
                         <v-checkbox
                                   v-model="form.marketing"
                                   @click="errors.marketing = null"
                                   value
                                   :error-messages="errors.marketing"
                         >
                          <template v-slot:label>
                              <div>
                                   <b>{{ $t('privacy.marketing.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.marketing.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.marketing.link')">{{ $t('privacy.marketing.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox>
                         <!-- NO SURVEY <v-checkbox 
                                   v-model="form.survey"
                                   @click="errors.survey = null"
                                   value
                                   :error-messages="errors.survey"
                         >
                          <template v-slot:label>
                               <div>
                                   <b>{{ $t('privacy.survey.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.survey.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.survey.link')">{{ $t('privacy.survey.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox> -->
                         <v-checkbox
                                   v-model="form.third_party"
                                   @click="errors.third_party = null"
                                   value
                                   :error-messages="errors.third_party"
                         >
                          <template v-slot:label>
                               <div>
                                   <b>{{ $t('privacy.third_party.label')}}</b> 
                                   <small>
                                   <br> {{$t('privacy.third_party.text') }} 
                                   <a target="_blank" @click.stop :href="$t('privacy.third_party.link')">{{ $t('privacy.third_party.text_link')}}</a>
                                   </small>
                              </div>
                          </template>
                         </v-checkbox>
                         <div style="white-space: pre-line">
                             <small>{{ $t('privacy.privacy.finaltext') }} 
                                  <a target="_blank" @click.stop :href="$t('privacy.privacy.link')">{{ $t('privacy.privacy.text_link')}}</a></small>
                         </div>
                    </v-col>
                    <v-col cols="12" class="my-4">
                         <v-btn color="primary" :loading="loading" block @click="createUser">{{$t('commons.create')}}</v-btn>
                    </v-col>
               </v-row>
          </v-col>
          <v-col cols="12" v-if="message">
                         <v-alert type="success" v-if="isAuthenticated">
                              {{message}}
                              <br>
                              <br>
                              <v-btn :to="{name:'manager.users.single',params:{id:uid}}" color="success">GO TO USER PAGE</v-btn>
                         </v-alert>
                         <v-alert type="success" v-if="!isAuthenticated">
                              {{message}}
                              <br>
                              <br>
                              <v-btn :to="{name:'register-store',params:{store_code:store.store_code}}" color="success">NEW REGISTRATION</v-btn>
                         </v-alert>
          </v-col>
     </v-row>
</template>
<script>
import {mapGetters} from "vuex";
import datepicker from '@/components/Commons/FormValues/datepicker.vue';

export default {
     name:"CreateUserForm",
     components:{
          datepicker
     },
     computed: {
     ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          titles: 'general/titles',
          prefixes: 'general/prefixes'
      }),
     },
     props:{
          store:{
               type: Object,
               required: true,
          }
     },
     data: () => ({
          loading: false,
          errors: {},
          message: null,
          uid: null,
          filters: null,
          maxdate: null,
          mindate: null,
          prefix: '',
          phone_number: '',
          form: {
               title_name: null,
               firstname: null,
               lastname: null,
               date_of_birth: null,
               email: null,
               phone: null,
               general: 0,
               marketign: 0,
               // survey: 0,
               third_party: 0,
          },
     }),
     beforeMount(){
          this.loading = true
          this.form.country = this.store.country
          this.form.store_uid = this.store.store_uid
          this.maxdate = this.$moment().subtract('18','years').format('YYYY-MM-DD')
          this.mindate = this.$moment().subtract('99','years').format('YYYY-MM-DD')
          this.form.date_of_birth = this.maxdate;
          if(!this.titles){
               console.log('No Titles Found')
               this.$store.dispatch('general/getTitles', this.$i18n.locale)
          }
          if(!this.prefixes){
               console.log('No Prefixes Found')
               this.$store.dispatch('general/getPrefixesApi')
          }
          this.loading = false
     },
     methods:{
          async createUser(){
               this.loading = true
               console.log(this.form)
               
               this.form.phone = this.form.prefix + this.form.phone_number;

               console.log('this.form.phone',this.form.phone)

               if(this.isAuthenticated){
                    this.$store.dispatch('managerUsers/createUser',this.form)
                    .then((response) => {
                         
                         this.message = response.data.message
                         this.uid = response.data.uid
                         console.log('CREATE success!',response)
                         this.loading = false;
                         
                    })
                    .catch((err) => {
                         console.error('CREATE ERROR!',err)
                         //this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 
               }
               else{
                    this.$store.dispatch('register/register',this.form)
                    .then((response) => {
                         this.message = response.data.message
                         this.uid = response.data.uid
                         console.log('CREATE success!',response)
                         this.loading = false;
                         
                    })
                    .catch((err) => {
                         console.error('CREATE ERROR!',err)
                         //this.message = err.response.data.message
                         this.errors = err.response.data.errors
                         this.loading = false;
                    }) 
               }
          },
          changeTitle(item){
               this.form.gender = item.gender;
               this.form.title_name = item.title;
          },
          changePrefix(item){
               this.form.prefix = item.prefix
               this.form.phone = this.form.prefix+this.form.phone_number;
          },
          changePhone(){
               this.form.phone = this.prefix+this.phone_number;
          },
          textPrefixItem: item => item.country + ' (' + item.prefix + ')',
     }
}
</script>
