import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          user: JSON.parse(localStorage.getItem('user')),
          addresses: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          address: null,
     },
     getters:{
          user: state => (state.user) ? state.user.data : null,
          addresses: state => state.addresses,
          meta: state => state.meta,
          address: state => state.address,
     },
     actions:{
          getUser({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('GET USER DATA',data)
                    apiClient.get("user", data).then(resp => {
                         commit('success', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET USER DATA ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateUserSettings({commit,dispatch},data){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE USER DATA',data)
                    apiClient.patch("user/update", data).then(resp => {
                         dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.success'), type: 'success'}, {root: true})
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('UPDATE USER DATA ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateUserProfile({commit,dispatch},data){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE USER DATA',data)
                    apiClient.patch("user/profile", data).then(resp => {
                         commit('success', resp);
                         dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.success'), type: 'success'}, {root: true})
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('UPDATE USER DATA ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateUserPrivacy({commit,dispatch},data){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE USER Privacy',data)
                    apiClient.patch("user/privacy", data).then(resp => {
                         commit('success', resp);
                         dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.success'), type: 'success'}, {root: true})
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('UPDATE USER Privacy ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          uploadUserAvatar({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE USER avatar',data)
                    apiClient.upload("user/avatar", data ).then(resp => {
                         commit('success', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('UPDATE USER avatar ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getUserAddresses({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('USER GET HIS ADDRESSES on page: '+state.meta.current_page, params)
                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null
                    apiClient.get("user/addresses?page="+state.meta.current_page,params).then(resp => {
                         commit('setAddresses', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET USER Addresses ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getUserAddress({commit}, id){
               return new Promise((resolve, reject) => {
                    console.log('GET USER Address =',id)
                    apiClient.get("user/addresses/single/"+id).then(resp => {
                         commit('setAddress', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET USER Address ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createUserAddress({commit}, address){
               return new Promise((resolve, reject) => {
                    console.log('CREATE USER Address =',address)
                    apiClient.post("user/addresses/create/",address).then(resp => {
                         resolve(resp);
                         commit('addAddressToList',resp);
                     })
                     .catch(err => {
                         console.log('CREATE USER Address ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateUserAddress({commit}, address){
               return new Promise((resolve, reject) => {
                    console.log('UPDATE USER Address =',address.id)
                    apiClient.patch("user/addresses/update/"+address.id,address).then(resp => {
                         resolve(resp);
                         commit('setAddress',resp);
                     })
                     .catch(err => {
                         console.log('GET USER Address ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          deleteUserAddress({commit}, address){
               return new Promise((resolve, reject) => {
                    console.log('DELETE USER Address =',address)
                    apiClient.delete("user/addresses/delete/"+address.id).then(resp => {
                         resolve(resp);
                         commit('removeAddressToList',address.id);
                     })
                     .catch(err => {
                         console.log('GET USER Address ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(err) {
              console.log('mutation error',err);
          },
          success(state, resp) {
               localStorage.setItem('user', JSON.stringify(resp.data))
               state.user = resp.data
               console.log('mutation success',resp);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setAddresses(state,resp){
               state.addresses = resp.data.data
               console.log('mutation setAddresses',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setAddress(state,resp){
               state.address = resp.data.data
               console.log('mutation setAddress',resp.data);
          },
          addAddressToList(state,resp){
               state.address = resp.data.data
               state.addresses.push(state.address)
          },
          removeAddressToList(state,resp){
               let index = state.addresses.findIndex(x=>x.id == resp)
               state.addresses.splice(index, 1);
               console.log('removeAddressToList',index,state.addresses[index])
          }
     }
}