import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     state:{
          countries: null,
          prefixes: [{"country":"Spain","prefix":"+34"},{"country":"Portugal","prefix":"+351"},{"country":"Italy","prefix":"+39"},{"country":"Greek","prefix":"+30"}],
          titles: null,
          stores: null,
          stores_meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
     },
     getters:{
          countries: state => state.countries,
          prefixes: state => state.prefixes,
          titles: state => state.titles,
          stores: state => state.stores,
          stores_meta: state => state.stores_meta,
     },
     actions:{
          getBarCodeImage({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('GET barcode',data)
                    apiClient.get("barcode/"+ data).then(resp => {
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('GET barcode ERROR', err)
                         commit('error', err);
                         reject(err);
                    });
               })
          },
          getCountries({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('GET countries')
                    apiClient.get("countries", data).then(resp => {
                         commit('setCountries', resp.data);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('GET COUNTRIES ERROR', err)
                         commit('error', err);
                         reject(err);
                    });
               })
          },
          getStores({commit,state},data){
               return new Promise((resolve, reject) => {
                    console.log('GET stores on page: '+state.stores_meta.current_page)
                    apiClient.get("stores?page="+state.stores_meta.current_page, data).then(resp => {
                         commit('setStores', resp);
                         resolve(resp);
                    })
                    .catch(err => {
                         console.log('GET stores ERROR', err)
                         commit('error', err);
                         reject(err);
                    });
               })
          },
          getPrefixesApi({commit},data){
            return new Promise((resolve, reject) => {
                 console.log('GET prefixes');
                 apiClient.get("prefix-numbers",data).then(resp => {
                      commit('setPrefixes', resp.data);
                      resolve(resp);
                  })
                  .catch(err => {
                      console.log('GET PREFIXES ERROR ', err)
                      commit('error', err);
                      reject(err);
                  });
            })
          },
          getPrefixes({state}){
               console.log('GET PREFIXES STATIC')
               return state.prefixes;
          },
          getTitles({commit},data){
               return new Promise((resolve, reject) => {
                    console.log('GET name-titles',data)
                    apiClient.get("name-titles?locale="+data).then(resp => {
                         commit('setTitles', resp.data);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET name-titles ERROR ', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          meta(state,meta){
               state.stores_meta = meta
               console.log('mutation meta',meta);
          },
        error(err) {
            console.log('mutation error',err);
        },
        success(resp) {
            console.log('mutation success',resp);
        },
        setCountries(state,resp) {
          state.countries = resp
          console.log('mutation countries success',resp);
        },
        setStores(state,resp) {
          state.stores = resp.data.data
          state.stores_meta = resp.data.meta
          console.log('mutation stores success', resp.data);
        },
        setPrefixes(state,resp) {
          state.prefixes = resp
          console.log('mutation prefixes success',resp);
        },
        setTitles(state,resp) {
          console.log('sate titles START',state.titles);
          state.titles = resp
          console.log('sate titles END',state.titles);
          console.log('mutation titles success',resp);
        },
     }
}