import commons from './pt/commons.json'
import attributes from './pt/attributes.json'
import errors from './pt/errors.json'
import roles from './pt/roles.json'
import user from './pt/user.json'
import admin from './pt/admin.json'
import manager from './pt/manager.json'
import router from './pt/router.json'
import filters from './pt/filters.json'
import analytics from './pt/analytics.json'
import privacy from './pt/privacy.json'

const lang = {
     manager: manager,
     admin: admin,
     user: user,
     roles: roles,
     errors: errors,
     attributes: attributes,
     commons: commons,
     analytics: analytics,
     filters: filters,
     privacy: privacy,
     router: router
}
export default lang