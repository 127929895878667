// import commons from './el/commons.json'
// import attributes from './el/attributes.json'
// import errors from './el/errors.json'
// import roles from './el/roles.json'
// import user from './el/user.json'
// import admin from './el/admin.json'
// import manager from './el/manager.json'
// import router from './el/router.json'
// import filters from './el/filters.json'
// import analytics from './el/analytics.json'
import privacy from './el/privacy.json'

const lang = {
     // manager: manager,
     // admin: admin,
     // user: user,
     // roles: roles,
     // errors: errors,
     // attributes: attributes,
     // commons: commons,
     // analytics: analytics,
     // filters: filters,
     privacy: privacy,
     // router: router
}
export default lang