import apiClient from '@/plugins/apiClient'
// import i18n from "@/plugins/i18n";
//const qs = require('qs');

export default {
     namespaced: true,
     state:{
          coupons: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          coupon:null
     },
     getters:{
          coupons: state => state.coupons,
          coupon: state => state.coupon,
          meta: state => state.meta,
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setCoupons(state,resp){
               state.coupons = resp.data.data
               console.log('mutation setCoupons',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setCoupon(state,resp){
               state.coupon = resp.data.data
               console.log('mutation setCoupon',resp.data.data);
          },
          removeCoupon(state,coupon_uid){
               var indexCoupon = state.coupons.findIndex(item => item.coupon_uid === coupon_uid);
               console.log('remove',indexCoupon)
               state.coupons.splice(indexCoupon, 1)
          }
     },
     actions:{
          getUserCoupons({commit,state}, uid){
               return new Promise((resolve, reject) => {
                    console.log('manager GET USER COUPONS on uid: '+uid)

                    apiClient.get("manager/users/single/"+uid+"/coupons?page="+state.meta.current_page).then(resp => {
                         resolve(resp);
                         commit('setCoupons', resp);
                     })
                     .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          useUserCoupon({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('manager USE USER COUPONS',data)
                    apiClient.post("manager/users/single/"+data.user_uid+"/coupons/use-coupon/",data)
                    .then(resp => {
                         resolve(resp);
                         commit('removeCoupon', data.coupon_uid)
                     })
                     .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          }
     },
}