import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          message: null,
          errors: {},
          users: [],
          user:null,
          activated: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
     },
     getters:{
          users: state => state.users,
          user: state => state.user,
          meta: state => state.meta,
          message: state => state.message,
          errors: state => state.errors,
          activated: state => state.activated,
     },
     mutations:{
          errors(state, err) {
               console.log('MUTATION errors', err);
               state.errors = err.data.errors;
               state.message = err.data.message;
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setUsers(state,resp){
               state.users = resp.data.data
               console.log('mutation setUsers',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setUser(state,resp){
               state.user = resp
               console.log('mutation setUser',resp);
          },
          reset(state){
               state.message =  null;
               state.activated = null;
               state.errors =  {};
               state.users =  [];
               state.user = null;
          },
          activate(state,resp){
               console.log('mutation activate',resp);
               state.message =  resp.data.message;
               state.activated = resp.data.uid;
               state.errors =  {};
               state.users =  [];
               state.user = null;
          }
     },
     actions:{
          searchUsers({commit,state}, params){
               commit('reset');
               return new Promise((resolve, reject) => {
                    console.log('manager GET USERS on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("manager/sleeper-users/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setUsers', resp);
                     })
                     .catch(err => {
                         console.log('manager GET USERS ERRROR', err)
                         commit('errors', err.response);
                         reject(err);
                     });
               })
          },
          setUser({commit}, user){
               console.log('action setUser', user);
               commit('setUser', user);
          },
          activateCard({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ACTIVATE CARD USER', data)
                    apiClient.post("manager/sleeper-users/activate/", data).then(resp => {
                         resolve(resp);
                         commit('activate',resp)
                     })
                     .catch(err => {
                         console.log('store auth actions REGISTRATION error ', err)
                         commit('errors', err.response);
                         reject(err);
                     });
               })
          },
     }
}