import apiClient from '@/plugins/apiClient'
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          coupons: [],
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
          coupon:null
     },
     getters:{
          coupons: state => state.coupons,
          coupon: state => state.coupon,
          meta: state => state.meta,
     },
     actions:{
          getCoupons({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET COUPONS on page: '+state.meta.current_page, params)

                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null

                    apiClient.get("admin/coupons/search?page="+state.meta.current_page+'&'+params).then(resp => {
                         resolve(resp);
                         commit('setCoupons', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET COUPONS ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          getCoupon({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN GET COUPON on uid: '+data)

                    apiClient.get("admin/coupons/single/"+data).then(resp => {
                         resolve(resp);
                         commit('setCoupon', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET COUPON ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          updateCoupon({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN UPDATE COUPON',data)

                    apiClient.patch("admin/coupons/single/"+data.coupon_uid+'/update', data).then(resp => {
                         resolve(resp);
                         commit('setCoupon', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET COUPON ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          createCoupon({commit}, data){
               return new Promise((resolve, reject) => {
                    console.log('ADMIN CREATE COUPON', data)
                    apiClient.post("admin/coupons/create/", data).then(resp => {
                         resolve(resp);
                         commit('setCoupon', resp);
                     })
                     .catch(err => {
                         console.log('ADMIN GET COUPON ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
          setMeta({commit}, payload){
               console.log('action meta',payload);
               commit('meta', payload);
          }
     },
     mutations:{
          error(err) {
               console.log('mutation error',err);
          },
          meta(state,meta){
               state.meta = meta
               console.log('mutation meta',meta);
          },
          setCoupons(state,resp){
               state.coupons = resp.data.data
               console.log('mutation setCoupons',resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          setCoupon(state,resp){
               state.coupon = resp.data.data
               console.log('mutation setCoupon',resp.data.data);
          }
     }
}