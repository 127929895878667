<template>
  <div class="home">
    <app-header></app-header>
    <Welcome :title="title"/>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from '@/components/Welcome.vue'
import Footer from '@/components/Layout/Footer'
import Header from '@/components/Layout/Header'

export default {
  name: 'Home',
  components: {
    Welcome,
    'app-footer': Footer,
    'app-header': Header,
  },
  data: () => ({
      env: process.env,
      title: process.env.VUE_APP_BASE_NAME
  }),
}
</script>
