import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from "@/store/modules/snackbar";
import snackBars from "@/store/modules/snackbars";
import auth from "@/store/modules/auth/";
import register from "@/store/modules/register/";
import loading from "@/store/modules/loading/";
import user from "@/store/modules/user/";
import userCoupons from "@/store/modules/user/coupons";
import general from "@/store/modules/general/";
import adminUsers from "@/store/modules/admin/users/";
import adminStores from "@/store/modules/admin/stores/";
import adminCoupons from "@/store/modules/admin/coupons/";
import adminReceipts from "@/store/modules/admin/receipts/";
import adminAnalytics from "@/store/modules/admin/analytics/";
import adminExports from "@/store/modules/admin/exports/";
import adminUsersReceipts from '@/store/modules/admin/users/receipts.js';
import adminUsersCoupons from '@/store/modules/admin/users/coupons.js';
import adminUsersCompletedCoupons from '@/store/modules/admin/users/completed-coupons.js';
import adminUsersStores from '@/store/modules/admin/users/stores.js';

import  managerStores from '@/store/modules/manager/stores/';
import  managerUsers from '@/store/modules/manager/users/';
import  managerSleeperUsers from '@/store/modules/manager/users/sleepers.js';
import  managerUsersCoupon from '@/store/modules/manager/users/coupons.js';
import  managerReceipts from '@/store/modules/manager/receipts/';
import  managerAnalytics from '@/store/modules/manager/analytics/';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    snackBars,
    snackbar,
    loading,
    auth,
    register,
    user,
    userCoupons,
    general,
    adminUsers,
    adminUsersReceipts,
    adminUsersCoupons,
    adminUsersCompletedCoupons,
    adminCoupons,
    adminStores,
    adminReceipts,
    adminAnalytics,
    adminExports,
    managerStores,
    managerUsers,
    managerSleeperUsers,
    managerUsersCoupon,
    managerReceipts,
    adminUsersStores,
    managerAnalytics,
  }
})
