import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ErrorPage from '../views/ErrorPage.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import store from "@/store";
import i18n from "@/plugins/i18n";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      auth: false,
    }
  },
  {
    path: '/register/:store_code',
    name: 'register-store',
    params: 'store_code',
    component: Register,
    meta: {
      auth: false,
    }
  },
  {
    path: '/password-forgot',
    name: 'password-forgot',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/PasswordForgot.vue'),
    meta: {
      auth: false,
    }
  },
  {
    path: '/resend-email-verification',
    name: 'resend-email-verification',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResendEmailVerification.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/email-verified',
    name: 'email-verified',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/EmailVerified.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/resend-sms-verification',
    name: 'resend-sms-verification',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResendSmsVerification.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/resend-card',
    name: 'resend-card',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResendCard.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/resend-privacy-confirmation',
    name: 'resend-privacy-confirmation',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResendPrivacyConfirmation.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/request-delete',
    name: 'request-delete',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RequestDelete.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/delete-confirmed',
    name: 'delete-confirmed',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ConfirmedDelete.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Support.vue'),
    meta: {
      auth: null,
    }
  },
  {
    path: '/dashboard',
    name: 'app',
    redirect: { name:'dashboard'},
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      auth: true,
      roles: ['user','admin','manager'],
    },
    children:[
      {
        path:'/',
        name: "dashboard",
        component: () => import(/* webpackChunkName: "user" */ '../views/users/HomeDash.vue'),
        meta: {
          auth: true,
          roles: ['user','admin','manager'],
        },
      },
      {
        path:'admin',
        name: "admin.index",
        redirect:{ name:'admin.dashboard'},
        component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/AdminDash.vue'),
        meta: {
          auth: true,
          roles: ['admin'],
        },
        children:[
          {
            path: '/',
            name: 'admin.dashboard',
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Home.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
          },
          {
            path: 'users',
            name: 'admin.users.index',
            redirect:{ name:'admin.users.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Users/Users.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.users.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Users/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.users.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Users/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
          {
            path: 'stores',
            name: 'admin.stores.index',
            redirect:{ name:'admin.stores.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Stores/Stores.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.stores.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Stores/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.stores.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Stores/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
          {
            path: 'coupons',
            name: 'admin.coupons.index',
            redirect:{ name:'admin.coupons.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Coupons/Coupons.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.coupons.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Coupons/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.coupons.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Coupons/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
          {    
            path: 'receipts',
            name: 'admin.receipts.index',
            redirect:{ name:'admin.receipts.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Receipts/Receipts.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.receipts.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Receipts/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.receipts.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Receipts/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
          {    
            path: 'analytics',
            name: 'admin.analytics.index',
            redirect:{ name:'admin.analytics.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Analytics/Analytics.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.analytics.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Analytics/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.analytics.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Analytics/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
          {    
            path: 'exports',
            name: 'admin.exports.index',
            redirect:{ name:'admin.exports.list'},
            component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Exports/Exports.vue'),
            meta: {
              auth: true,
              roles: ['admin'],
            },
            children:[
              {
                path: '/',
                name: 'admin.exports.list',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Exports/List.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'admin.exports.single',
                component: () => import(/* webpackChunkName: "admin" */ '../views/users/admin/Exports/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['admin'],
                },
              },
            ]
          },
        ]
      },
      {
        path:'manager',
        name: "manager.index",
        redirect:{ name:'manager.dashboard'},
        component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/ManagerDash.vue'),
        meta: {
          auth: true,
          roles: ['manager'],
        },
        children:[
          {
            path: '/',
            name: 'manager.dashboard',
            component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Home.vue'),
            meta: {
              auth: true,
              roles: ['manager'],
            },
          },
          {
            path: 'users',
            name: 'manager.users.index',
            redirect:{ name:'manager.users.list'},
            component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Users/Users.vue'),
            meta: {
              auth: true,
              roles: ['manager'],
            },
            children:[
              {
                path: '/',
                name: 'manager.users.list',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Users/List.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'manager.users.single',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Users/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
            ]
          },
          {
            path: 'stores',
            name: 'manager.stores.index',
            redirect:{ name:'manager.stores.list'},
            component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Stores/Stores.vue'),
            meta: {
              auth: true,
              roles: ['manager'],
            },
            children:[
              {
                path: '/',
                name: 'manager.stores.list',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Stores/List.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'manager.stores.single',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Stores/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
            ]
          },
          {
            path: 'receipts',
            name: 'manager.receipts.index',
            redirect:{ name:'manager.receipts.create'},
            component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Receipts/Receipts.vue'),
            meta: {
              auth: true,
              roles: ['manager'],
            },
            children:[
              {
                path: '/',
                name: 'manager.receipts.create',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Receipts/Create.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'manager.receipts.single',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Receipts/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
            ]
          },
          {    
            path: 'analytics',
            name: 'manager.analytics.index',
            redirect:{ name:'manager.analytics.list'},
            component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Analytics/Analytics.vue'),
            meta: {
              auth: true,
              roles: ['manager'],
            },
            children:[
              {
                path: '/',
                name: 'manager.analytics.list',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Analytics/List.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
              {
                path: 'single/:id',
                params: 'id',
                name: 'manager.analytics.single',
                component: () => import(/* webpackChunkName: "manager" */ '../views/users/manager/Analytics/Single.vue'),
                meta: {
                  auth: true,
                  roles: ['manager'],
                },
              },
            ]
          },
        ]
      },
      {
        path:'user',
        name: "user.index",
        redirect:{ name:'user.dashboard'},
        component: () => import(/* webpackChunkName: "user" */ '../views/users/user/UserDash.vue'),
        meta: {
          auth: true,
          roles: ['user'],
        },
        children:[
          {
            path: '/',
            name: 'user.dashboard',
            component: () => import(/* webpackChunkName: "user" */ '../views/users/user/Home.vue'),
            meta: {
              auth: true,
              roles: ['user'],
            },
          },
          // {
          //   path: 'profile',
          //   name: 'user.profile',
          //   component: () => import(/* webpackChunkName: "user" */ '../views/users/user/Profile.vue'),
          //   meta: {
          //     auth: true,
          //     roles: ['user'],
          //   },
          // },
          // {
          //   path: 'settings',
          //   name: 'user.settings',
          //   component: () => import(/* webpackChunkName: "user" */ '../views/users/user/Settings.vue'),
          //   meta: {
          //     auth: true,
          //     roles: ['user'],
          //   },
          // },
        ]
      },
    ]
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: {
      auth: null,
    }
  },
  {
    path: '*',
    redirect:{name:'error',query:{'error':'404'}},
    meta: {
      auth: null,
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters['auth/isAuthenticated']
  let userRoles = store.getters['auth/roles']

  // se authenticazione richiesta e non è autenticato vai a login
  if( to.meta.auth==true && !isAuthenticated){
    store.dispatch('snackbar/showSnackBar', {message: i18n.t('errors.not-permitted'), type: 'error'})
    next({name:"login"});
  }

  // se authenticazione non è richiesta ed è autenticato vai a dashboard
  if( to.meta.auth==false && isAuthenticated){
    store.dispatch('snackbar/showSnackBar', {message: i18n.t('errors.already-logged'), type: 'success'})
    next({name:'dashboard'});
  }

  // se sono richiesti dei ruoli, l'utente ha ruoli, e i ruoli richiesti non sono iclusi torna alla login
  if (to.meta.roles && userRoles && !userRoles.some(r=> to.meta.roles.includes(r)) ) {
    store.dispatch('snackbar/showSnackBar', {message: i18n.t('errors.not-permitted'), type: 'error'})
    next({name:'error',query:{'error':'403'}});
  }

 // SEO AND BREADCRUMBS

  document.title = ' | '+ process.env.VUE_APP_BASE_NAME;
  var metaDescr = document.createElement('meta');
      metaDescr.setAttribute('name', 'description');
      metaDescr.content = document.title;
  
  var metaKeys = document.createElement('meta');
      metaKeys.setAttribute('name', 'keywords');
      metaKeys.content = process.env.VUE_APP_BASE_NAME;
  
  var metaThemeColor = document.createElement('meta');
    metaThemeColor.setAttribute('name', 'theme-color');
    metaThemeColor.setAttribute('id', 'theme-color');
    metaThemeColor.content = "#B2152C";
  
  var metaAuthor = document.createElement('meta');
    metaAuthor.setAttribute('name', 'author');
    metaAuthor.content = process.env.VUE_APP_BASE_NAME;

  var metaImage = document.createElement('meta');
    metaImage.setAttribute('name', 'og:image');
    metaImage.setAttribute('property', 'og:image');
    metaImage.content = "/img/social/social.jpg";

  let parentsRoute = to.matched
  to.meta.breadCrumbs = []
  parentsRoute.forEach(parent => {
    to.meta.breadCrumbs.push({text:parent.name,href:'#'+parent.path})
    document.title = i18n.t('router.'+parent.name)+' - '+document.title;
    metaDescr.content = document.title;
    metaKeys.content = i18n.t('router.'+parent.name)+', '+metaKeys.content;
  });


  document.getElementsByTagName('head')[0].appendChild(metaDescr);
  document.getElementsByTagName('head')[0].appendChild(metaKeys);
  document.querySelector('meta[name="theme-color"]').remove();
  document.getElementsByTagName('head')[0].appendChild(metaThemeColor);
  document.getElementsByTagName('head')[0].appendChild(metaAuthor);
  document.getElementsByTagName('head')[0].appendChild(metaImage);

  next()

});

export default router
