import apiClient from '@/plugins/apiClient'
import i18n from "@/plugins/i18n";
const qs = require('qs');

export default {
     namespaced: true,
     state:{
          coupons: null,
          meta: {
               current_page: 1,
               total: 0,
               per_page: 12
          },
     },
     getters:{
          coupons: state => state.coupons,
          meta: state => state.meta,
     },
     mutations:{
          setCoupons(state,resp){
               state.coupons = resp.data.data
               console.log('mutation set Coupons List', resp.data.data);
               state.meta = resp.data.meta
               console.log('mutation setMeta',resp.data.meta);
          },
          error({dispatch},err){
               dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.error'), type: 'error'}, {root: true})
               console.log('mutation error',err);
          },
          success({dispatch}){
               dispatch('snackbar/showSnackBar', {message: i18n.t('commons.messages.success'), type: 'success'}, {root: true})
               console.log('mutation SUCCESS');
          }
     },
     actions:{
          getUserCoupons({commit,state}, params){
               return new Promise((resolve, reject) => {
                    console.log('USER GET HIS OWN COUPONS on page: '+state.meta.current_page, params)
                    params = (params) ? qs.stringify(params, { arrayFormat: 'indices' }) : null
                    apiClient.get("user/coupons?page="+state.meta.current_page,params).then(resp => {
                         commit('setCoupons', resp);
                         resolve(resp);
                     })
                     .catch(err => {
                         console.log('GET USER Coupons ERRROR', err)
                         commit('error', err);
                         reject(err);
                     });
               })
          },
     }
}