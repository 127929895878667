import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
         light: {
           primary: "#B2152C", // #B2152C
           secondary: colors.indigo.darken1, // #FFCDD2
           accent: colors.indigo.base, // #3F51B5
           gold: "#e7bd42",
           silver: "#aaa9ad",
           bronze: "#905923",
         },
         dark: {
           primary: colors.red.lighten1,
           secondary: colors.red.darken1,
           accent: colors.indigo.base
         },
      },
    },
})

