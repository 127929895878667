import apiClient from '@/plugins/apiClient'

export default {
     namespaced: true,
     actions:{
         register({commit},data){
          return new Promise((resolve, reject) => {
               console.log('REGISTER NEW USER',data)
               apiClient.post("register", data).then(resp => {
                    commit('success', resp.data);
                    resolve(resp);
                })
                .catch(err => {
                    console.log('store auth actions REGISTRATION error ', err)
                    commit('error', err);
                    reject(err);
                });
          })
         },
         resendEmail({commit},data){
            return new Promise((resolve, reject) => {
                console.log('resend-email-verification',data)
                apiClient.post("resend-email-verification", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         resendSms({commit},data){
            return new Promise((resolve, reject) => {
                console.log('resend-sms-verification',data)
                apiClient.post("resend-sms-verification", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         resendEmailPrivacyConfirm({commit},data){
            return new Promise((resolve, reject) => {
                console.log('resend-card',data)
                apiClient.post("resend-privacy-confirm", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         sendEmailRequestDelete({commit},data){
            return new Promise((resolve, reject) => {
                console.log('resend-card',data)
                apiClient.post("request-delete", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         resendCard({commit},data){
            return new Promise((resolve, reject) => {
                console.log('resend-card',data)
                apiClient.post("resend-card", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         verifyPhone({commit},data){
            return new Promise((resolve, reject) => {
                console.log('sms-verify',data)
                apiClient.post("sms-verify", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         forgot({commit},data){
            return new Promise((resolve, reject) => {
                console.log('forgot-password',data)
                apiClient.post("forgot-password", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         },
         passwordReset({commit},data){
            return new Promise((resolve, reject) => {
                console.log('forgot-password',data)
                apiClient.post("forgot-password/reset", data).then(resp => {
                     commit('success', resp.data);
                     resolve(resp);
                 })
                 .catch(err => {
                     console.log('store auth actions RESEND error ', err)
                     commit('error', err);
                     reject(err);
                 });
           })
         }
     },
     mutations:{
        error(err) {
            console.log('mutation error',err);
        },
        success(resp) {
            console.log('mutation success',resp);
        },
     }
}