<template>
<v-container fluid class="pa-0">
     <v-app-bar
       color="primary"
       dark
     >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isAuthenticated"></v-app-bar-nav-icon>
          
          <img class="mr-3" :src="require('@/assets/icon.svg')" height="40"/>
          <v-toolbar-title class="text-uppercase">{{title}}</v-toolbar-title>
         
          <v-spacer></v-spacer>
          <user-icon></user-icon>
          <locales-nav></locales-nav>

     </v-app-bar>
     <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
      v-if="isAuthenticated"
     >
     <user-nav></user-nav>
    </v-navigation-drawer>
</v-container>          
</template>
<script>
import UserIcon from '@/components/Layout/user/UserIcon.vue'
import UserNav from '@/components/Layout/user/UserNav.vue'
import LocalesNav from '@/components/Layout/user/LocalesNav.vue'
import {mapGetters} from "vuex";


export default {
     name: "Header",
     computed: {
     ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          roles: 'auth/roles'
      })
     },
     components:{
          "user-icon": UserIcon,
          "user-nav": UserNav,
          "locales-nav": LocalesNav,
     },
     data: () => ({
      drawer: false,
      group: null,
      env: process.env,
      title: process.env.VUE_APP_BASE_NAME
     }),
     watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>