import commons from './es/commons.json'
import attributes from './es/attributes.json'
import errors from './es/errors.json'
import roles from './es/roles.json'
import user from './es/user.json'
import admin from './es/admin.json'
import manager from './es/manager.json'
import router from './es/router.json'
import filters from './es/filters.json'
import analytics from './es/analytics.json'
import privacy from './es/privacy.json'

const lang = {
     manager: manager,
     admin: admin,
     user: user,
     roles: roles,
     errors: errors,
     attributes: attributes,
     commons: commons,
     analytics: analytics,
     filters: filters,
     privacy: privacy,
     router: router
}
export default lang