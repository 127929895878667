 import en from './en.js'
 import es from './es.js'
 import it from './it.js'
 import pt from './pt.js'
 import el from './el.js' // greco moderno

if(!localStorage.getItem('language')){
    localStorage.setItem('language', navigator.language.substr(0,2))
}

export const defaultLocale = (localStorage.getItem('language'))  ? localStorage.getItem('language') : "en";

export const languages = {
     en: en,
     es: es,
     it: it,
     pt: pt,
     el: el,
}